<template>
  <v-card
    class="overflow-hidden"
    min-height="calc(100vh - 68px)"
    color="accent"
  >
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template> <b-breadcrumb :items="breadCrumbs"></b-breadcrumb> </template>

    <v-banner sticky dark px-6>
      <v-row>
        <v-col cols="2">
          <BackActionBar @goback="clickOnBack"></BackActionBar>
        </v-col>
        <v-col cols="8">
          <ContextualActionBar
            :subtitle="' '"
            :title="serviceType + '  ' + cardData.name"
            :tooltip="true"
          ></ContextualActionBar>
        </v-col>
        <v-col
          cols="2"
          class="pr-3 pl-0 pt-6 text-right"
          v-if="userProfile && userProfile != 'readonly'"
        >
          <v-btn
            v-if="
              this.editedTableValue.tableTopologiaEdit.length == 0 &&
              this.editedTableValue.tableTopologiaRsEdit.length == 0
            "
            rounded
            color="secondary"
            dark
            class="ma-1 menuBtn"
            @click="clicOnReset()"
            disabled
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="clicOnReset()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>RESET</span>
          </v-tooltip>
          <v-btn
            v-if="
              this.editedTableValue.tableTopologiaEdit.length == 0 &&
              this.editedTableValue.tableTopologiaRsEdit.length == 0
            "
            rounded
            color="secondary"
            dark
            class="ma-1 menuBtn"
            @click="clicOnSave()"
            disabled
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="clicOnSave()"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>SALVA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="getInfoService(true, id)"
                ><v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <span>INFO</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="exportExcel()"
                ><v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="_goTAD(true, id)"
                >TAD
              </v-btn>
            </template>
            <span>TAD</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="returnSLRLink(cardData.came)"
                >SLR
              </v-btn>
            </template>
            <span>SLR</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="4"
          class="pr-3 pl-0 pt-6 text-right"
          v-if="userProfile && userProfile == 'readonly'"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="exportExcel()"
                ><v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="getInfoService(true, id)"
                ><v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <span>INFO</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="_goTAD(true, id)"
                >TAD
              </v-btn>
            </template>
            <span>TAD</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click.stop="returnSLRLink(cardData.came)"
                >SLR
              </v-btn>
            </template>
            <span>SLR</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-alert type="success" v-if="successMsg">
        Salvataggio avvenuto con successo.
      </v-alert>
      <v-alert type="error" v-if="errorMsg">
        Errore durante il salvataggio.
      </v-alert>

      <v-row class="d-flex flex-row justify-center align-center">
        <v-col cols="12">
          <div>
            <div id="infoFirstLine">
              <span class="secondary--text">CCAME</span>
              {{ serviceCame ? serviceCame : "N/A" }}
              &ensp;
              <span class="secondary--text">Classe servizio</span>&nbsp;
              <em class="font-weight-regular">{{
                cardData.clazz ? cardData.clazz : "N/A"
              }}</em>
              &ensp;
              <span class="secondary--text">Conformità Classe di Servizio </span
              >&nbsp;
              <img
                class="imgicon"
                v-if="cardData.complianceClass == 'CONFORME CON NOTE'"
                :src="require('../assets/conforme-note.png')"
              />
              <img
                class="imgicon"
                v-if="cardData.complianceClass == 'CONFORME'"
                :src="require('../assets/conforme.png')"
              />
              <img
                class="imgicon"
                v-if="cardData.complianceClass == 'NON CONFORME'"
                :src="require('../assets/non-conforme.png')"
              />
              <img
                class="imgicon"
                v-if="cardData.complianceClass == 'FUORI PERIMETRO'"
                :src="require('../assets/fuori-perimetro.png')"
              />
              <img
                class="imgicon"
                v-if="cardData.complianceClass == ''"
                :src="require('../assets/fuori-perimetro.png')"
              />
              &ensp;
              <em class="font-weight-regular">{{
                cardData.complianceClass
                  ? cardData.complianceClass
                  : cardData.toolsEnvironment == "PROD"
                  ? "N/A"
                  : "N/A - NOPROD"
              }}</em>
              &ensp;<span class="secondary--text">Stato Catalogo</span>&nbsp;
              <em class="font-weight-regular">{{
                cardData.state ? cardData.state : "N/A"
              }}</em>
              &nbsp;<span class="secondary--text">Area Dettaglio</span>&nbsp;
              <em class="font-weight-regular">{{
                cardData.dandDArea ? cardData.dandDArea : "N/A"
              }}</em>
              &ensp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <em v-bind="attrs" v-on="on" class="font-weight-regular">
                    {{ cardData.contact ? cardData.contact : "N/A" }}</em
                  >
                  <span class="secondary--text">Referente Operativo</span>&nbsp;
                </template>
                <em class="font-weight-regular">
                  {{ cardData.contact ? cardData.contact : "N/A" }}</em
                >
              </v-tooltip>
            </div>
            <div id="infoSecondLine">
              <span class="secondary--text">Data Aggiornamento</span>&nbsp;
              <em class="font-weight-regular">{{
                cardData.dataAggiornamento ? cardData.dataAggiornamento : "N/A"
              }}</em
              >&nbsp;<span v-if="cardData.updatedByUser" class="secondary--text"
                >Data Ultima Modifica</span
              >&nbsp;
              <em v-if="cardData.updatedByUser" class="font-weight-regular"
                >{{
                  cardData.dataUltimaModifica
                    ? cardData.dataUltimaModifica
                    : "N/A"
                }}
                <span class="redColor"
                  >Attenzione informazioni compliance verranno ricalcolate con
                  il prossimo aggiornamento</span
                ></em
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-banner>

    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto pt-0"
      max-height="calc(100vh - 157px)"
      color="accent"
    >
      <perfect-scrollbar>
        <v-container fluid>
          <v-row>
            <v-col cols="12" v-if="!resetEvent">
              <OpCardDetailsTabs
                ref="clear"
                :type="cardData.type"
                :label="cardData.label"
                :name="cardData.name"
                :came="cardData.came"
                :compliance="cardData.complianceClass"
                :toolsEnvironment="cardData.toolsEnvironment"
                :edit="edit"
                @modified="sendToParent"
              ></OpCardDetailsTabs>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </v-sheet>
    <ModalConfirm
      @open-dialog-confirm="updateDialogConfirm"
      :dialogConfirm="openDialogConfirm"
      :text="dialogText"
      v-on:confirmed="save()"
    ></ModalConfirm>

    <ModalAlert
      @open-dialog-alert="updateDialogAlert"
      :dialogAlert="openDialogAlert"
      :alert="infoAlerts"
      :title="alertTitle"
    ></ModalAlert>

    <ModalInfo
      @open-dialog-info="updateDialogInfo"
      :dialogInfo="openDialogInfo"
      :info="infoItems"
    ></ModalInfo>
  </v-card>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import OpCardDetailsTabs from "@/components/OpCardDetailsTabs.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import ModalAlert from "@/components/ModalAlert.vue";
import download from "downloadjs";
import ModalInfo from "@/components/ModalInfo.vue";
import BackActionBar from "../components/core/BackActionBar.vue";

export default {
  name: "OperatingCardDetails",
  components: {
    ContextualActionBar,
    OpCardDetailsTabs,
    ModalConfirm,
    ModalAlert,
    ModalInfo,
    BackActionBar,
  },
  mixins: [connectionMixin],
  data() {
    return {
      lockId: "",
      id: this.$route.params.id,
      edit: false,
      cardData: {},
      displayProgress: true,
      backUrl: "",
      openDialogConfirm: false,
      dialogText: "",
      alertTitle: "ATTENZIONE",
      resetEvent: false,
      editedTableValue: {
        tableTopologiaEdit: [],
        tableTopologiaRsEdit: [],
        adminServices: [],
      },
      openDialogAlert: false,
      openDialogInfo: false,
      infoAlerts: [],
      successMsg: false,
      errorMsg: false,
      shown: false,
      infoItems: {},
      items: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Manage",
          href: "#",
        },
        {
          text: "Library",
          active: true,
        },
      ],
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Schede Operative",
          to: { name: "OperatingCards" },
        },
        {
          text: "Scheda Operativa",
          href: "",
        },
      ],
      userProfile: null,
    };
  },
  computed: {
    serviceCame() {
      if (Object.keys(this.cardData).length === 0) {
        return "N/A";
      } else {
        var splittedString = this.cardData.name.split("_");
        let size = splittedString.length;
        return splittedString[size - 1];
      }
    },
    serviceType() {
      if (Object.keys(this.cardData).length === 0) {
        return "";
      } else {
        var str = this.cardData.type;
        var updatedString = str.charAt(0).toUpperCase() + str.slice(1);

        return updatedString.replace("_", " ") + ":";
      }
    },
  },
  mounted() {
    this.getUserProfile();
    this.setBackUrl();
    this.getDetailCame();
  },
  beforeDestroy() {
    this.releaseEditLock();
  },
  methods: {
    async lockEditResource() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/cards/" + this.id,
          this.opts
        );
        console.log("lockEditResource", response.data);
        this.lockId = response.data.id;
        this.displayProgress = false;
        return false;
      } catch (error) {
        console.log("error", error);
        if (error.response.status == 423) {
          alert(error?.response.data.username + " sta modificando la risorsa!");
        } else {
          alert("Si prega di ricaricare la pagina");
        }
        this.displayProgress = false;
        return true;
      }
    },
    async releaseEditLock() {
      if (!this.lockId) {
        return;
      }
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/delete/" + this.lockId,
          this.opts
        );
        console.log("releaseEditLock", response.data);
        this.lockId = "";
        this.displayProgress = false;
      } catch (error) {
        console.log("error", error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        this.userProfile = response.data;

        if (this.$route.params.edit == "true" && this.userProfile == "user") {
          const locked = await this.lockEditResource();
          if (!locked) {
            this.edit = true;
          }
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    setBackUrl() {
      if (this.$route.params.fgdata) {
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Dashboard",
            to: "/opplanimpactandprogress/" + this.$route.params.fgdata.idPlan,
          },

          {
            text: "Failover Group",
            to: "/opplanfailovergroups/" + this.$route.params.fgdata.idPlan,
          },
          {
            text: "Dettaglio",
            to: {
              name: "OpPlanFailoverGroupsDetails",
              params: {
                fgdata: this.$route.params.fgdata,
                planId: this.$route.params.fgdata.idPlan,
                failoverGroupId: this.$route.params.fgdata.idFailover,
              },
            },
          },
          {
            text: "Servizio",
            to: {
              name: "OpEditServiceProgress",
              params: {
                fgdata: this.$route.params.fgdata,
              },
            },
          },
          {
            text: "Schede Operative",
            href: "",
          },
        ];
        this.backUrl =
          "/opplanfailovergroups/" + this.$route.params.fgdata.idPlan;
      } else {
        this.backUrl = "";
      }
    },
    goback() {
      if (this.$route.params.fgdata) {
        this.$router.push({
          name: "OpEditServiceProgress",
          params: {
            fgdata: this.$route.params.fgdata,
          },
        });
      } else {
        this.$router.push("/OperatingCards");
      }
    },
    async getDetailCame() {
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.id,
          this.opts
        );
        this.cardData = response.data;
        this.updateDisplayProgress(false);
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    async update() {
      var bodyData = {
        ucmdbPut: false,
        updateSP: false,
        infrastructureTopologies: this.editedTableValue.tableTopologiaEdit,
        rsTopologies: this.editedTableValue.tableTopologiaRsEdit,
        adminServices: [],
      };
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            this.$env.value("API_UPDATE"),
          bodyData,
          this.opts
        );
        this.editedTableValue.tableTopologiaEdit = [];
        this.editedTableValue.tableTopologiaRsEdit = [];
        //this.reset();
        this.displayProgress = false;
        if (response.data.errorCount > 0) {
          this.infoAlerts = response.data.errorMessages;
          this.updateDialogAlert(true);
        } else {
          this.successMsg = true;
          this.getDetailCame();
          setInterval(() => {
            this.successMsg = false;
          }, 10000);
        }
      } catch (error) {
        console.log(error);
        this.displayProgress = false;
        this.errorMsg = true;
        setInterval(() => {
          this.errorMsg = false;
        }, 10000);
      }
    },
    updateDisplayProgress(value) {
      this.displayProgress = value;
    },
    updateDialogAlert(value) {
      this.openDialogAlert = value;
    },
    sendToParent(value) {
      this.editedTableValue = value;
    },
    clicOnSave() {
      this.dialogText = "Le modifiche verranno propagate su uCMDB. Confermi?";
      this.updateDialogConfirm(true);
    },
    clicOnReset() {
      this.dialogText = "Vuoi resettare le modifiche?";
      this.updateDialogConfirm(true);
    },
    clickOnBack() {
      if (
        this.editedTableValue.tableTopologiaEdit.length > 0 ||
        this.editedTableValue.tableTopologiaRsEdit.length > 0
      ) {
        this.dialogText = "Tornando indietro perderai le modifiche";
        this.updateDialogConfirm(true);
      } else {
        this.goback();
      }
    },
    reset() {
      this.resetEvent = true;
      this.displayProgress = true;
      setTimeout(() => {
        this.resetEvent = false;
        this.displayProgress = false;
        this.editedTableValue.tableTopologiaEdit = [];
        this.editedTableValue.tableTopologiaRsEdit = [];
      }, 500);
    },
    save() {
      if (
        this.dialogText == "Le modifiche verranno propagate su uCMDB. Confermi?"
      ) {
        this.update();
        this.updateDialogConfirm(false);
        this.clearTable();
      }
      if (this.dialogText == "Vuoi resettare le modifiche?") {
        this.reset();
        this.updateDialogConfirm(false);
      }
      if (this.dialogText == "Tornando indietro perderai le modifiche") {
        this.goback();
        this.updateDialogConfirm(false);
      }
      if (this.dialogText == "Uscendo dalla pagina perderai le modifiche") {
        this.goTAD();
        this.updateDialogConfirm(false);
      }
    },
    async exportExcel() {
      this.cardData.name.replace("/", "-").replace("\\\\", " ");
      try {
        this.loading = true;
        this.$emit("overlay-download", this.loading);
        const response = await this.$http.get(
          this.$env.value("API_EXPORT_EXCEL") + "/" + this.id,
          this.optsXls
        );

        const content = response.headers["application/vnd.ms-Excel"];
        download(response.data, this.cardData.name + ".xlsx", content);
        this.loading = false;
        this.$emit("overlay-download", this.loading);
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
    },
    clearTable() {
      this.$refs.clear.clearTable();
    },
    updateDialogInfo(value) {
      if (!value) {
        this.shown = false;
      } else {
        this.shown = true;
      }
      this.openDialogInfo = value;
    },
    async getInfoService(value, id) {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            id +
            this.$env.value("API_INFO_SERVICE"),
          this.opts
        );
        this.infoItems = response.data;

        this.updateDialogInfo(value);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    goTAD() {
      let routeData = this.$router.resolve({
        name: "OperatingCardDetailsRS",
        params: {
          id: this.$route.params.id,
        },
      });
      window.open(routeData.href, "_self");
    },
    _goTAD() {
      if (
        this.editedTableValue.tableTopologiaEdit.length > 0 ||
        this.editedTableValue.tableTopologiaRsEdit.length > 0
      ) {
        this.dialogText = "Uscendo dalla pagina perderai le modifiche";
        this.updateDialogConfirm(true);
      } else {
        this.goTAD();
      }
    },
    returnSLRLink(came) {
      var url =
        "https://servicemanager.eni.com/sm9/index.do?ctx=docEngine&file=ENISlrAnag&query=came.code%3D%22" +
        came +
        "%22&action=&title=ENISlrAnag%20" +
        came;
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped>
.ps {
  height: calc(100vh - 269px);
  width: 100%;
}
.redColor {
  color: red;
  font-size: smaller;
}
.row {
  margin: 0px !important;
}
#infoFirstLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
}
#infoSecondLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
}
.imgicon {
  width: 20px;
}
</style>
