<template>
  <v-sheet elevation="1">
    <v-chart
      class="chart"
      :option="option"
      theme="enitheme"
      :manual-update="false"
      autoresize
      :loading="barLoading"
      :loadingOptions="barLoadingOptions"
    />
  </v-sheet>
</template>

<script>
import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import theme from "@/assets/enitheme.json";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
]);
// registering custom theme
registerTheme("enitheme", theme);

export default {
  name: "ChartRiepilogo",
  components: {
    VChart,
  },
  props: {
    chart: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      barLoading: false,
      barLoadingOptions: {
        text: "Caricamento…",
        textColor: "#fff",
        color: "#fdd300",
        maskColor: "rgba(0, 0, 0, 0.7)",
      },

      option: {
        title: {
          text: "",
          top: 10,
          left: "center",
          textStyle: { fontWeight: "small", fontSize: "10" },
        },
        tooltip: {},
        legend: {
          data: [
            "Virtuali stetched",
            "Virtuali non stretched",
            "Fisici x86",
            "Unix",
            "unknownNodes",
          ],
          top: 10,
          right: 0,
          left: 25,
        },
        grid: {
          top: "35%",
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        dataset: {
          dimensions: [
            "name",
            "virtualStretchedNodes",
            "virtualNonStretchedNodes",
            "x86Nodes",
            "unixNodes",
            "unknownNodes",
          ],
          source: [],
        },
        xAxis: { type: "category", axisLabel: { rotate: 30, interval: 0 } },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            name: "Virtuali stetched",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "Virtuali non stretched",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "Fisici x86",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },

          {
            name: "Unix",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "unknownNodes",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.getOption();
    }, 500);
  },

  methods: {
    getOption() {
      const final_data = Array.from(this.chart);
      this.option.dataset.source = final_data;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 190px;
}
</style>
