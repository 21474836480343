<template>
  <div id="tabsDetails">
    <v-tabs v-model="tab" background-color="secondary darken-1" centered>
      <v-tab href="#tab-0"> Riepilogo </v-tab>
      <v-tab href="#tab-1"> Topologia Running Software </v-tab>
      <v-tab href="#tab-2"> Topologia Infrastruttura </v-tab>
      <v-tab href="#tab-3"> Dipendenze </v-tab>
      <v-tab href="#tab-4"> Storage/NAS </v-tab>
      <v-tabs-slider color="accent"></v-tabs-slider>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-0" class="secondary darken-1 pt-2">
        <RiepilogoService
          :compliance="compliance"
          :toolsEnvironment="toolsEnvironment"
        >
        </RiepilogoService>
      </v-tab-item>
      <v-tab-item value="tab-1" class="secondary darken-1 pt-2">
        <DataTableTopologiaRs
          ref="clear"
          :edit="edit"
          @modifiedRs="sendToParentFromTableTopologiaRs"
        >
        </DataTableTopologiaRs>
      </v-tab-item>
      <v-tab-item value="tab-2" class="secondary darken-1 pt-2">
        <DataTableTopologia
          ref="clear"
          :edit="edit"
          @modified="sendToParentFromTableTopologia"
        >
        </DataTableTopologia>
      </v-tab-item>
      <v-tab-item value="tab-3" class="secondary darken-1 pt-2">
        <Dependencies :card_came="came" :card_name="name"></Dependencies>
      </v-tab-item>
      <v-tab-item value="tab-4" class="secondary darken-1 pt-2">
        <DependenciesStorage
          :card_came="came"
          :card_name="name"
        ></DependenciesStorage>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DataTableTopologia from "./DataTableTopologia.vue";
import DataTableTopologiaRs from "./DataTableTopologiaRs.vue";
import Dependencies from "./Dependencies.vue";
import DependenciesStorage from "./DependenciesStorage.vue";
import RiepilogoService from "./RiepilogoService.vue";
export default {
  components: {
    DataTableTopologia,
    DataTableTopologiaRs,
    Dependencies,
    DependenciesStorage,
    RiepilogoService,
  },
  props: {
    type: String,
    label: String,
    name: String,
    compliance: String,
    came: Number,
    edit: Boolean,
    toolsEnvironment: String,
  },
  data() {
    return {
      tab: "tab-0",
      tablesChanges: {
        tableTopologiaEdit: [],
        tableTopologiaRsEdit: [],
        trigger: 0,
      },
    };
  },
  mounted() {
    if (this.$route.params.edit == "true") {
      this.tab = "tab-1";
    } else {
      this.tab = "tab-0";
    }

    if (this.$route.params.tab && this.$route.params.tab.includes("tab-")) {
      this.tab = this.$route.params.tab;
    }
  },
  methods: {
    sendToParentFromTableTopologia(value) {
      this.tablesChanges.tableTopologiaEdit = [];
      value.forEach((el) => {
        if (el.edit == true) {
          var updates = {};
          updates.keys = el.keys;
          updates.nodeNote = el.nodeNote;
          this.tablesChanges.tableTopologiaEdit.push(updates);
        }
      });
      this.$emit("modified", this.tablesChanges);
    },
    sendToParentFromTableTopologiaRs(value) {
      this.tablesChanges.tableTopologiaRsEdit = [];
      value.forEach((el) => {
        if (el.edit == true) {
          var updates = {};
          updates.keys = el.keys;
          updates.sequenceStart = el.sequenceStart;
          updates.resilienceApplication = el.resilienceApplication;
          updates.resilienceApplicationCI = el.resilienceApplicationCI;
          updates.issue = el.issue;
          updates.noteResiliency = el.noteResiliency;
          this.tablesChanges.tableTopologiaRsEdit.push(updates);
        }
      });

      this.$emit("modified", this.tablesChanges);
    },
    clearTable() {
      this.$refs.clear.clearTable();
    },
  },
  created() {},
};
</script>

<style scoped>
.ps {
  height: calc(100vh - 280px);
}
</style>
