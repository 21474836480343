<template>
  <v-card>
    <v-card-title class="search-row-dttopologia">
      <v-text-field
        class="searchStyle"
        v-model="search"
        label="Cerca"
        single-line
        hide-details
      ></v-text-field>
      <v-btn rounded color="primary" @click="getFilteredData()">
        <v-icon small class="mr-2"> mdi-magnify </v-icon>
      </v-btn>

      <v-checkbox
        class="small-label"
        v-model="infoAT"
        label="Mostra solo anomalie e punti di attenzione"
        hide-details
        @change="getFilteredData()"
      ></v-checkbox>

      <v-checkbox
        class="small-label"
        v-model="infoGDC"
        label="Mostra solo Server al GDC"
        hide-details
        @change="getFilteredData()"
      ></v-checkbox>

      <v-checkbox
        class="small-label"
        v-model="infoBA"
        label="Mostra info BA"
        hide-details
        @change="getFilteredData()"
      ></v-checkbox>

      <v-checkbox
        class="small-label"
        v-model="infoLO"
        label="Mostra info LO"
        hide-details
        @change="getFilteredData()"
      ></v-checkbox>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      v-if="reload"
      :headers="headerTable"
      :items="records"
      :calculate-widths="false"
      class="main-table elevation-1 mt-0 headerTable"
      :loading="loading"
      loading-text="Caricamento dati... Attendere, per favore."
      multi-sort
      fixed-header
      :items-per-page="-1"
      height="calc(100vh - 450px)"
      :item-class="itemRowBackground"
    >
      <template v-slot:top>
        <v-toolbar>
          <v-dialog v-model="dialog" max-width="800px">
            <v-card style="padding-top: 30px">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.nodeDisplayLabel"
                        label="[Node] : Display Label"
                        :disabled="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.s0DisplayLabel"
                        label="[SolutionL0] : Display Label"
                        :disabled="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.nodeNote"
                        label="Eni NOTE"
                        rows="1"
                        :counter="4000"
                        :rules="[checktextArea]"
                        v-on:change="checkItem"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions v-if="edit">
                <v-spacer></v-spacer>
                <v-btn color="black darken-1" text @click="close">
                  Annulla
                </v-btn>
                <v-btn
                  color="black darken-1"
                  text
                  @click="save"
                  :disabled="!texAreaError || itemChanged"
                >
                  Conferma
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`header.complianceStatus`]>
        <div class="text-center">
          <v-icon style="color: white">mdi-list-status</v-icon>
        </div>
      </template>
      <template v-slot:[`header.sequenceStatus`]>
        <div class="text-center">
          <v-icon style="color: white">mdi-counter</v-icon>
        </div>
      </template>
      <template v-slot:[`body.prepend`]>
        <tr class="primary lighten-4 dt-topologia-search-column">
          <td v-if="edit"></td>
          <td>
            <div class="text-center">
              <v-select
                v-model="selectedComplianceStatus"
                :items="complianceStatuses"
                item-text="label"
                return-object
                single-line
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-icon v-if="index < 1" :color="item.color">
                    mdi-circle
                  </v-icon>
                  <span
                    v-if="index === 1"
                    class="text-grey text-caption align-self-center"
                  >
                    +{{ selectedComplianceStatus.length - 1 }}
                  </span>
                </template>
              </v-select>
            </div>
          </td>
          <td>
            <div class="text-center">
              <v-select
                v-model="selectedSequenceStatus"
                :items="sequenceStatuses"
                item-text="label"
                return-object
                single-line
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-icon v-if="index < 1" :color="item.color">
                    mdi-circle
                  </v-icon>
                  <span
                    v-if="index === 1"
                    class="text-grey text-caption align-self-center"
                  >
                    +{{ selectedSequenceStatus.length - 1 }}
                  </span>
                </template>
              </v-select>
            </div>
          </td>
          <td v-if="infoBA">
            <v-text-field
              v-model="businessApplication"
              type="text"
              label="BusinessApplication"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td v-if="infoLO">
            <v-text-field
              v-model="solutionL0"
              type="text"
              label="SolutionL0"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="nodeDL"
              type="text"
              label="[Node]:DL"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="lifecycleStatus"
              type="text"
              label="LifecycleStatus"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="calculatedLocation"
              type="text"
              label="CalculatedLocation"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="nodeIsVirtual"
              type="text"
              label="Node id Virtual"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="vmWare"
              type="text"
              label="VMware"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="vmDatacenter"
              type="text"
              label="VM Datacenter"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="vmCluster"
              type="text"
              label="VM Cluster"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="resilienzaServer"
              type="text"
              label="Resilienza Server"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="osFriendlyName"
              type="text"
              label="OS Friendly Name"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="discoveredModel"
              type="text"
              label="DiscoveredModel"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="nodeOwnerDL"
              type="text"
              label="Owner Display Label"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="eniIssue"
              type="text"
              label="Issue"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="eniNote"
              type="text"
              label="Note"
              color="grey lighten-2"
            ></v-text-field>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.actions`]="{ item }" v-if="edit">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:[`item.complianceStatus`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              :style="{ color: getComplianceStatusColor(item) }"
            >
              mdi-circle
            </v-icon>
          </template>
          <div v-if="item.rsComplianceMap.length > 0">
            <div v-for="(rcItem, k) in item.rsComplianceMap" :key="k">
              <span style="font-weight: bold; color: #fdd300">
                {{ rcItem.value }}
                <span> RS </span>
              </span>
              <span style="font-weight: bold"> - </span>
              <span>{{ rcItem.key }}</span>
            </div>
          </div>
          <div v-else>
            <span style="font-weight: bold; color: #fdd300">
              {{ item.complianceStatus }}
            </span>
          </div>
          <div
            v-if="
              item.complianceStatus.includes('CONFORME CON RTO SELETTIVO') &&
              item.resilienceApplication === 'NA_BIZ'
            "
            style="font-weight: bold; color: #ff5252; max-width: 400px"
          >
            N.B. all'interno di questo server sono presenti Running Software per
            cui è stato accettato dal Business un RTO Selettivo maggiore di
            quanto indicato nella VDR - vedi il campo “[Running Software]: Note”
            nel tab “Topologia Running Software”.
          </div>
        </v-tooltip>
      </template>
      <template v-slot:[`item.sequenceStatus`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              :style="{ color: getSequenceStatusColor(item) }"
            >
              mdi-circle
            </v-icon>
          </template>
          <div v-if="item.rsSequenceMap.length > 0">
            <div v-for="(rsItem, k) in item.rsSequenceMap" :key="k">
              <span style="font-weight: bold; color: #fdd300">
                {{ rsItem.value }}
                <span> RS</span>
              </span>
              <span style="font-weight: bold"> - </span>
              <span>{{ rsItem.key }}</span>
            </div>
          </div>
          <div v-else>
            <span style="font-weight: bold; color: #fdd300">
              Running Software NON presenti
            </span>
          </div>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
export default {
  mixins: [connectionMixin],
  props: {
    edit: Boolean,
  },
  data: () => ({
    loading: false,
    records: [],
    search: "",
    infoAT: false,
    infoGDC: false,
    infoBA: false,
    infoLO: false,
    header: [],
    businessApplication: "",
    solutionL0: "",
    nodeDL: "",
    lifecycleStatus: "",
    calculatedLocation: "",
    nodeIsVirtual: "",
    vmWare: "",
    vmDatacenter: "",
    vmCluster: "",
    resilienzaServer: "",
    osFriendlyName: "",
    discoveredModel: "",
    nodeOwnerDL: "",
    eniIssue: "",
    eniNote: "",
    dialog: false,
    reload: true,
    editedIndex: -1,
    editedItem: {
      text: "",
      align: "",
      value: "",
      sortable: true,
      class: "",
    },
    defaultItem: {
      text: "",
      align: "",
      value: "",
      sortable: true,
      class: "",
    },
    texAreaError: false,
    itemChanged: true,
    complianceStatuses: [
      { label: "", color: "#444", index: -1 },
      { label: "N/A - NOPROD", color: "#444", index: 0 },
      { label: "FUORI PERIMETRO", color: "#444", index: 1 },
      { label: "CONFORME", color: "#4CAF50", index: 2 },
      {
        label: "CONFORME CON RTO SELETTIVO (NA_BIZ)",
        color: "#2196F3",
        index: 3,
      },
      { label: "CONFORME CON NOTE", color: "#fdd300", index: 4 },
      { label: "NON CONFORME", color: "#FF5252", index: 5 },
      { label: "CLASSE SERVIZIO NON RICONOSCIUTA", color: "#FF5252", index: 6 },
      { label: "CLASSE SERVIZIO NON PRESENTE", color: "#FF5252", index: 7 },
    ],
    selectedComplianceStatus: [],
    sequenceStatuses: [
      { label: "", color: "#444", index: -1 },
      { label: "N/A - NOPROD", color: "#444", index: 0 },
      { label: "SEQUENZA ASSENTE", color: "#FF5252", index: 1 },
      { label: "SEQUENZA PRESENTE", color: "#4CAF50", index: 2 },
    ],
    selectedSequenceStatus: [],
  }),
  computed: {
    headerTable() {
      this.setHeader();
      return this.header;
    },
  },
  mounted() {
    this.getFilteredData();
  },
  methods: {
    getComplianceStatusColor(rs) {
      const cs = this.complianceStatuses.find(
        (el) => el.label === rs.complianceStatus
      );

      return cs.color;
    },
    getSequenceStatusColor(rs) {
      const cs = this.sequenceStatuses.find(
        (el) => el.label === rs.sequenceStatus
      );

      return cs.color;
    },
    setHeader() {
      let that = this;
      this.header = [
        {
          text: "Compliance Status",
          align: "center",
          value: "complianceStatus",
          sortable: true,
          sort: (a, b) => {
            const aind = that.complianceStatuses.find(
              (el) => el.label === a
            ).index;
            const bind = that.complianceStatuses.find(
              (el) => el.label === b
            ).index;
            return aind - bind;
          },
          class: "primary lighten-4 white--text text-top white-bg",
          width: 100,
          filter: (value) => {
            return this.selectedComplianceStatus.length > 0
              ? this.selectedComplianceStatus.find((el) => el.label === value)
              : true;
          },
        },
        {
          text: "Sequence Status",
          align: "center",
          value: "sequenceStatus",
          sortable: true,
          sort: (a, b) => {
            const aind = that.sequenceStatuses.find(
              (el) => el.label === a
            ).index;
            const bind = that.sequenceStatuses.find(
              (el) => el.label === b
            ).index;
            return aind - bind;
          },
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (value) => {
            return this.selectedSequenceStatus.length > 0
              ? this.selectedSequenceStatus.find((el) => el.label === value)
              : true;
          },
        },
        {
          text: "[Node] : Display Label",
          align: "center",
          value: "nodeDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 110,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["nodeDL"].toLowerCase());
          },
        },
        {
          text: "[Node] : LifecycleStatus",
          align: "center",
          value: "nodeLifecycleStatus",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["lifecycleStatus"].toLowerCase());
          },
        },
        {
          text: "[Node] : CalculatedLocation",
          align: "center",
          value: "nodeCalculatedLocation",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["calculatedLocation"].toLowerCase());
          },
        },
        {
          text: "[Node] : Node is Virtual",
          align: "center",
          value: "nodeIsVirtual",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 120,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["nodeIsVirtual"].toLowerCase());
          },
        },
        {
          text: "[Node] : VMware Cluster is Stretched",
          align: "center",
          value: "isStretched",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["vmWare"].toLowerCase());
          },
        },
        {
          text: "[Node] : VM Datacenter",
          align: "center",
          value: "datacenter",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["vmDatacenter"].toLowerCase());
          },
        },
        {
          text: "[Node] : VM Cluster",
          align: "center",
          value: "cluster",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["vmCluster"].toLowerCase());
          },
        },
        {
          text: "[Node] : ENI Resilienza Server",
          align: "center",
          value: "resilienceInfra",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["resilienzaServer"].toLowerCase());
          },
        },
        {
          text: "[Node] : OS Friendly Name",
          align: "center",
          value: "nodeOSFriendlyName",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 130,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["osFriendlyName"].toLowerCase());
          },
        },
        {
          text: "[Node] : DiscoveredModel",
          align: "center",
          value: "nodeDiscoveredModel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["discoveredModel"].toLowerCase());
          },
        },
        {
          text: "[Node] : [Owner] - Display Label",
          align: "center",
          value: "nodeOwnerDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["nodeOwnerDL"].toLowerCase());
          },
        },
        {
          text: "[Node] : ENI Issue",
          align: "center",
          value: "issue",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["eniIssue"].toLowerCase());
          },
        },
        {
          text: "[Node] : ENI Note",
          align: "center",
          value: "nodeNote",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["eniNote"].toLowerCase());
          },
        },
      ];

      if (this.infoLO) {
        this.header.splice(2, 0, {
          text: "[SolutionL0] : Display Label",
          align: "center",
          value: "s0DisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          cellClass: "cellFont",
          width: 110,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["solutionL0"].toLowerCase());
          },
        });
      }
      if (this.infoBA) {
        this.header.splice(2, 0, {
          text: "[BusinessApplication] : \nDisplay Label",
          align: "center",
          value: "baDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 160,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["businessApplication"].toLowerCase());
          },
        });
      }
      if (this.edit) {
        this.header.splice(0, 0, {
          text: "Action",
          align: "center",
          value: "actions",
          sortable: false,
          class: "primary lighten-4 white--text text-top",
        });
      }
    },
    editItem(item) {
      this.editedIndex = this.records.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    itemRowBackground: function (item) {
      let backGround = "";
      if (item.hasRs == false) {
        backGround = "backgroundColorRed";
      }
      var index = this.records.indexOf(item);
      if (this.records[index].edit == true) {
        backGround = "backgroundColor";
      }
      console.log(backGround);

      backGround += this.edit ? " rowtwothreewhite" : " rowonetwowhite";
      return backGround;
    },
    checkItem() {
      if (this.records[this.editedIndex].nodeNote == this.editedItem.nodeNote) {
        this.itemChanged = true;
      } else {
        this.itemChanged = false;
      }
    },
    save() {
      if (this.editedIndex > -1) {
        this.records.forEach((el, idx) => {
          if (
            el.keys.schedaUcmdbId ==
              this.records[this.editedIndex].keys.schedaUcmdbId &&
            idx != this.editedIndex
          ) {
            if (
              this.records[this.editedIndex].nodeNote !==
              this.editedItem.nodeNote
            ) {
              el.nodeNote = this.editedItem.nodeNote;
              el.edit = true;
            }
          }
        });
        Object.assign(this.records[this.editedIndex], this.editedItem);
        this.records[this.editedIndex].edit = true;
        this.$emit("modified", this.records);

        this.close();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async getFilteredData() {
      var bodyData = {
        onlyAnomalies: this.infoAT,
        onlyGDC: this.infoGDC,
        infoBA: this.infoBA,
        infoL0: this.infoLO,
      };
      this.loading = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.$route.params.id +
            this.$env.value("API_INFRASTRUCTURE") +
            "/" +
            this.search,
          bodyData,
          this.opts
        );
        this.records = response.data;
        this.loading = false;
      } catch (error) {
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    checktextArea(input) {
      this.texAreaError = false;
      if (input && input.length > 4000) {
        this.texAreaError = false;
      } else {
        this.texAreaError = true;
      }
      return this.texAreaError;
    },
    clearTable() {
      this.reload = false;
      this.records.forEach((el) => {
        if (el.edit) {
          el.edit = false;
        }
        setTimeout(() => {
          this.reload = true;
        }, 200);
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    //  this.initialize();
  },
};
</script>

<style scoped>
::v-deep .small-label .v-label {
  font-size: 0.8em;
}

::v-deep .rowonetwowhite td:nth-of-type(1),
::v-deep .rowonetwowhite td:nth-of-type(2),
::v-deep .rowtwothreewhite td:nth-of-type(2),
::v-deep .rowtwothreewhite td:nth-of-type(3) {
  background: white !important;
}

.headerFont {
  font-size: 20px;
}
.v-data-table >>> tbody td {
  font-size: 12px !important;
}
.main-table table {
  table-layout: fixed;
}

.v-input.searchStyle.v-input--hide-details.theme--light.v-text-field.v-text-field--single-line.v-text-field--is-booted {
  max-width: 20%;
}
.v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.primary--text {
  padding: 0px 15px;
}
.search-row-dttopologia {
  margin-top: -24px;
}

.search-row-dttopologia .v-btn {
  height: 26px;
  min-width: 36px;
  padding: 0 0px;
  margin-top: 15px;
}

.search-row-dttopologia .v-btn .v-icon {
  margin-right: 0px !important;
}
.v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
  padding-left: 10px;
}
</style>
