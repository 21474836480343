<template>
  <v-dialog
    v-model="dialogAlert"
    scrollable
    width="90%"
    @click:outside="closedialogAlert()"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list two-line>
          <template>
            <v-data-table
              dense
              :headers="headers"
              :items="alert"
              :items-per-page="30"
              calculate-widths
              :footer-props="footerProps"
              class="elevation-1"
            ></v-data-table>
          </template>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closedialogAlert"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogAlert: {
      default: false,
      type: Boolean,
    },
    alert: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      footerProps: { "items-per-page-options": [30, 60, 90, -1] },
      headers: [
        {
          text: "CAME",
          value: "came",
          width: "10%",
        },
        { text: "NAME", value: "name", width: "20%" },
        {
          text: "DISPLAYLABEL",
          value: "displayLabel",
          width: "70%",
        },
      ],
    };
  },
  computed: {},
  methods: {
    closedialogAlert() {
      this.$emit("open-dialog-alert", false);
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
td.text-start {
  font-size: 10px !important;
}
</style>
