<template>
  <v-card>
    <v-row class="marginRow" id="riepilogoService">
      <v-col class="paddingCol" cols="3"
        ><div class="infoData">
          <span class="infoTitle">Classe servizio</span>
          <p>
            {{
              data.clazz
                ? data.clazz
                : toolsEnvironment == "PROD"
                ? "N/A"
                : "N/A - NOPROD"
            }}
          </p>
        </div></v-col
      >
      <v-col class="paddingCol" cols="3"
        ><div class="infoData">
          <span class="infoTitle">Disponibilità</span>
          <p>
            {{
              data.availability
                ? data.availability
                : toolsEnvironment == "PROD"
                ? "N/A"
                : "N/A - NOPROD"
            }}
          </p>
        </div></v-col
      ><v-col class="paddingCol" cols="3"
        ><div class="infoData">
          <span class="infoTitle">RTO</span>
          <p>
            {{
              data.rto
                ? data.rto
                : toolsEnvironment == "PROD"
                ? "N/A"
                : "N/A - NOPROD"
            }}
          </p>
        </div></v-col
      ><v-col class="paddingCol" cols="3"
        ><div class="infoData">
          <span class="infoTitle">RPO</span>
          <p>
            {{
              data.rpo
                ? data.rpo
                : toolsEnvironment == "PROD"
                ? "N/A"
                : "N/A - NOPROD"
            }}
          </p>
        </div></v-col
      >

      <v-col class="paddingCol" cols="2"
        ><div class="infoDataColor" :class="setClassCompliance(compliance)">
          <span class="infoTitleColor">Conformità Classe di Servizio</span>
          <p>
            {{
              compliance
                ? compliance
                : toolsEnvironment == "PROD"
                ? "N/A"
                : "N/A - NOPROD"
            }}
          </p>
        </div></v-col
      >
      <v-col class="paddingCol" cols="2"
        ><div :class="[setClassNoRed(data.startingSequence), infoDataColor()]">
          <span :class="infoTitleColor()">Sequenze ripartenza completate</span>
          <p v-if="data.startingSequence">
            {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
          </p>
          <p v-if="!data.startingSequence">
            {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
          </p>
        </div></v-col
      ><v-col class="paddingCol" cols="2"
        ><div :class="[setClassNO(data.businnesSharedSW), infoDataColor()]">
          <span :class="infoTitleColor()"
            >RTO Selettivo Condiviso con il Business</span
          >
          <p v-if="data.businnesSharedSW">SI</p>
          <p v-if="!data.businnesSharedSW">NO</p>
        </div></v-col
      >
      <v-col class="paddingCol" cols="3"
        ><div class="infoData">
          <span class="infoTitle">Condivisione Server</span>
          <p v-if="data.sharedServers && data.sharedServers.length > 0">
            SI
            <v-icon
              class="iconInfo"
              color="secondary"
              @click="
                updateDialogAlert(
                  true,
                  'Condivisione Server',
                  data.sharedServers
                )
              "
            >
              mdi-information-outline</v-icon
            >
          </p>
          <p v-if="data.sharedServers && data.sharedServers.length == 0">NO</p>
        </div></v-col
      >
      <v-col class="paddingCol" cols="3"
        ><div class="infoData">
          <span class="infoTitle">Condivisione Running Software</span>
          <p v-if="data.sharedRS && data.sharedRS.length > 0">
            SI
            <v-icon
              color="secondary"
              @click="
                updateDialogAlert(
                  true,
                  'Condivisione Running Software',
                  data.sharedRS
                )
              "
            >
              mdi-information-outline</v-icon
            >
          </p>
          <p v-if="data.sharedRS && data.sharedRS.length == 0">NO</p>
        </div>
      </v-col>
      <v-col class="paddingCol" cols="6"
        ><div class="infoData">
          <span class="boxtitle">Sintesi Nodi</span>
          <v-row class="marginRow"
            ><v-col class="paddingCol" cols="4"
              ><div class="infoData">
                <span class="infoTitle">Numero totale nodi</span>
                <p class="textBig">{{ data.nodes }}</p>
              </div></v-col
            ><v-col class="paddingCol" cols="4"
              ><div class="infoData">
                <span class="infoTitle">Numero nodi Basiglio</span>
                <p>{{ data.basiglioNodes }}</p>
              </div></v-col
            ><v-col class="paddingCol" cols="4"
              ><div class="infoData">
                <span class="infoTitle">Numero nodi altre location</span>
                <p>{{ data.otherLocationNodes }}</p>
              </div></v-col
            ><v-col class="paddingCol" cols="12"
              ><div class="infoData">
                <v-row class="marginRow">
                  <v-col class="paddingCol" cols="4"
                    ><img :src="require('../assets/gdc_icon.png')" />
                  </v-col>
                  <v-col class="paddingCol" cols="4">
                    <div class="infoData">
                      <span class="infoTitle">Nodi al GDC:</span>
                      <p>{{ data.gdcNodes }}</p>
                    </div>
                  </v-col>
                  <v-col class="paddingCol" cols="4">
                    <div class="infoData">
                      <span class="infoTitle">in CAGE G1--I1</span>
                      <p>{{ data.cageG1Nodes }}</p>
                    </div>
                  </v-col>
                  <v-col class="paddingCol" cols="12" v-if="loaded">
                    <ChartRiepilogo :chart="chart"></ChartRiepilogo></v-col
                  ><v-col class="paddingCol" cols="12">
                    <div class="infoData">
                      <span class="boxtitle">ANOMALIE SUI NODI</span>
                      <v-row class="marginRow"
                        ><v-col class="paddingCol" cols="3">
                          <div
                            :class="[
                              setClassNO(data.repositionOpportunity),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >Opportunità di Riposizionamento</span
                            >
                            <p v-if="data.repositionOpportunity">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="!data.repositionOpportunity">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div>
                        </v-col>

                        <v-col class="paddingCol" cols="3">
                          <div
                            :class="[
                              setClassSiRed(data.repositionNoCompliance),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >Non conformi ma Riposizionabili</span
                            >
                            <p v-if="data.repositionNoCompliance">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="!data.repositionNoCompliance">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div>
                        </v-col>

                        <v-col class="paddingCol" cols="3">
                          <div
                            :class="[
                              setClassSiRed(data.nodesNoRSCC2),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >SENZA RS censito (OS gestito da CC2)</span
                            >
                            <p v-if="data.nodesNoRSCC2">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="!data.nodesNoRSCC2">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div>
                        </v-col>

                        <v-col class="paddingCol" cols="3">
                          <div
                            :class="[
                              setClassSiRed(data.nodesNoRSOthers),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >SENZA RS censito (OS gestito da AM/Altri)</span
                            >
                            <p v-if="data.nodesNoRSOthers">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="!data.nodesNoRSOthers">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div>
                        </v-col></v-row
                      >
                    </div></v-col
                  >
                </v-row>
              </div></v-col
            ></v-row
          >
        </div>
      </v-col>
      <v-col class="paddingCol" cols="6"
        ><div class="infoData">
          <span class="boxtitle">Sintesi Running Software</span>

          <v-row class="marginRow"
            ><v-col class="paddingCol" cols="4"
              ><div class="infoData">
                <span class="infoTitle">Numero totale RS</span>
                <p class="textBig">{{ data.rstotal }}</p>
              </div></v-col
            ><v-col class="paddingCol" cols="4"
              ><div class="infoData">
                <span class="infoTitle">Numero RS Basiglio</span>
                <p>{{ data.basiglioRS }}</p>
              </div></v-col
            ><v-col class="paddingCol" cols="4"
              ><div class="infoData">
                <span class="infoTitle">Numero RS altre location</span>
                <p>{{ data.otherLocationRS }}</p>
              </div></v-col
            ><v-col class="paddingCol" cols="12">
              <div class="infoData">
                <v-row class="marginRow"
                  ><v-col class="paddingCol" cols="6"
                    ><img :src="require('../assets/gdc_icon.png')" /> </v-col
                  ><v-col class="paddingCol" cols="6">
                    <div class="infoData">
                      <span class="infoTitle">RS al GDC</span>
                      <p>{{ data.gdcRS }}</p>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="infoData">
                      <span class="boxtitle"
                        >ANOMALIE SULLA RESILIENZA DEI RUNNING SOFTWARE</span
                      >
                      <v-row class="marginRow">
                        <v-col class="paddingCol" cols="4">
                          <div
                            :class="[
                              setClassSiRed(data.swNoCompliance),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >NON soddisfatta / NON ripartibile</span
                            >
                            <p v-if="data.swNoCompliance">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="!data.swNoCompliance">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div></v-col
                        ><v-col class="paddingCol" cols="4">
                          <div
                            :class="[
                              setClassSW(data.swComplianceCC2),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >da Valorizzare (RS in carico ai CC2)</span
                            >
                            <p v-if="data.swComplianceCC2 == 'MAJOR'">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="data.swComplianceCC2 == 'MINOR'">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="data.swComplianceCC2 == 'NO'">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div></v-col
                        >

                        <v-col class="paddingCol" cols="4">
                          <div
                            :class="[
                              setClassSW(data.swComplianceOthers),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >da Valorizzare (RS in carico a AM/Altri)</span
                            >
                            <p v-if="data.swComplianceOthers == 'MAJOR'">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="data.swComplianceOthers == 'MINOR'">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="data.swComplianceOthers == 'NO'">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div></v-col
                        >
                      </v-row>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="infoData">
                      <span class="boxtitle"
                        >ANOMALIE SULLE SEQUENZE DEI RUNNING SOFTWARE</span
                      >
                      <v-row class="marginRow">
                        <v-col class="paddingCol" cols="6">
                          <div
                            :class="[
                              setClassSiRed(data.swSequenceCC2),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >Assenti (RS in carico a CC2)</span
                            >
                            <p v-if="data.swSequenceCC2">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="!data.swSequenceCC2">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div></v-col
                        ><v-col class="paddingCol" cols="6">
                          <div
                            :class="[
                              setClassSiRed(data.swSequenceOthers),
                              infoDataColor(),
                            ]"
                          >
                            <span :class="infoTitleColor()"
                              >Assenti (RS in carico a AM/Altri)</span
                            >
                            <p v-if="data.swSequenceOthers">
                              {{ toolsEnvironment == "PROD" ? "SI" : "N/A" }}
                            </p>
                            <p v-if="!data.swSequenceOthers">
                              {{ toolsEnvironment == "PROD" ? "NO" : "N/A" }}
                            </p>
                          </div></v-col
                        >
                      </v-row>
                    </div>
                  </v-col></v-row
                >
              </div></v-col
            ></v-row
          >
        </div></v-col
      >
    </v-row>
    <ModalTable
      @open-dialog-alert="updateDialogAlert"
      :dialogAlert="openDialogAlert"
      :alert="infoAlerts"
      :title="alertTitle"
    ></ModalTable
  ></v-card>
</template>
<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ChartRiepilogo from "./chart/ChartRiepilogo.vue";
import ModalTable from "./ModalTable.vue";
export default {
  name: "RiepilogoService",
  components: {
    ChartRiepilogo,
    ModalTable,
  },
  mixins: [connectionMixin],
  props: {
    compliance: String,
    toolsEnvironment: String,
    default: () => "p",
  },
  data: () => ({
    loading: false,
    data: {},
    sheredServerList: [],
    sheredRsList: [],
    chart: [],
    loaded: true,
    infoAlerts: [],
    openDialogAlert: false,
    alertTitle: "",
  }),
  computed: {},
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      this.loaded = false;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.$route.params.id +
            this.$env.value("API_CARDS_SUMMARY"),
          this.opts
        );
        this.data = response.data;
        await this.waitChart(response.data);
        this.returnSharedRs();
        this.returnSharedServers();
        this.loading = false;
      } catch (error) {
        alert("Si prega di ricaricare la pagina");
        console.log(error);
        this.loading = false;
      }
    },
    async waitChart(data) {
      return new Promise((resolve) => {
        this.createArrayChart(data);
        resolve();
      });
    },
    createArrayChart(data) {
      const chartData = {
        virtualStretchedNodes: data.virtualStretchedNodes,
        x86Nodes: data.x86Nodes,
        virtualNonStretchedNodes: data.virtualNonStretchedNodes,
        unixNodes: data.unixNodes,
        unknownNodes: data.unknownNodes,
      };
      this.chart.push({ ...chartData });
      this.loaded = true;
    },
    returnSharedRs() {
      this.data.sharedRS.sort((a, b) => (a.name < b.name ? -1 : 1));
    },
    returnSharedServers() {
      this.data.sharedServers.sort((a, b) => (a.name < b.name ? -1 : 1));
    },
    updateDialogAlert(value, title = "", data = []) {
      this.alertTitle = title;
      if (data.length == 0) {
        if (title == "Condivisione Server") {
          data.push("Nessun Server Condiviso");
        } else {
          data.push("Nessun RS Condiviso per RS");
        }
      }
      this.infoAlerts = data;
      this.openDialogAlert = value;
    },
    setClassCompliance(val) {
      let cssClass = "";
      if (this.toolsEnvironment == "PROD") {
        if (val == "CONFORME CON NOTE") {
          cssClass = "backgroundYellow";
        } else if (val == "CONFORME") {
          cssClass = "backgroundGreen";
        } else if (val == "NON CONFORME") {
          cssClass = "backgroundRed";
        } else {
          cssClass = "backgroundGray";
        }
      } else {
        cssClass = "backgroundGray";
      }
      return cssClass;
    },
    setClassNO(val) {
      let cssClass = "";
      if (this.toolsEnvironment == "PROD") {
        if (val) {
          cssClass = "backgroundYellow";
        } else {
          cssClass = "backgroundGreen";
        }
      }
      return cssClass;
    },
    setClassSiRed(val) {
      let cssClass = "";
      if (this.toolsEnvironment == "PROD") {
        if (val) {
          cssClass = "backgroundRed";
        } else {
          cssClass = "backgroundGreen";
        }
      }
      return cssClass;
    },
    setClassNoRed(val) {
      let cssClass = "";
      if (this.toolsEnvironment == "PROD") {
        if (!val) {
          cssClass = "backgroundRed";
        } else {
          cssClass = "backgroundGreen";
        }
      }
      return cssClass;
    },
    setClassSI(val) {
      let cssClass = "";
      if (this.toolsEnvironment == "PROD") {
        if (!val) {
          cssClass = "backgroundYellow";
        } else {
          cssClass = "backgroundGreen";
        }
      }
      return cssClass;
    },
    setClassSW(val) {
      let cssClass = "";
      if (this.toolsEnvironment == "PROD") {
        if (val == "MINOR") {
          cssClass = "backgroundYellow";
        } else if (val == "MAJOR") {
          cssClass = "backgroundRed";
        } else {
          cssClass = "backgroundGreen";
        }
      }
      return cssClass;
    },
    infoDataColor() {
      let cssClass = "";
      if (this.toolsEnvironment == "PROD") {
        cssClass = "infoDataColor";
      } else {
        cssClass = "infoData";
      }
      return cssClass;
    },
    infoTitleColor() {
      let cssClass = "";
      if (this.toolsEnvironment == "PROD") {
        cssClass = "infoTitleColor";
      } else {
        cssClass = "infoTitle";
      }
      return cssClass;
    },
  },
  watch: {},
  created() {
    //  this.initialize();
  },
};
</script>

<style scoped>
#riepilogoService {
  background-color: #444444;
  padding-top: 10px;
}
.infoData {
  text-align: center;
  border: 1px solid #fdd300;
  color: white;
  padding: 0px;
  min-height: 65px;
}
.infoDataColor {
  padding: 10px;
  text-align: center;
  border: 1px solid #fdd300;
  color: rgb(0, 0, 0);
  padding: 5px;
}
.infoTitle {
  font-size: 10px;
  color: #deb800;
}
.infoTitleColor {
  font-size: 10px;
  color: #000000;
}
.boxtitle {
  font-size: 15px;
  color: #deb800;
}
.backgroundGreen {
  background-color: #6bd36b;
}
.backgroundYellow {
  background-color: #fdd300;
}
.backgroundRed {
  background-color: #b56464;
}
.backgroundGray {
  background-color: #b7b7b7;
}
img {
  width: 50px;
}
.paddingCol {
  padding: 5px 15px !important;
}
.marginRow {
  margin: 0px !important;
}
.ps {
  height: calc(100vh - 325px) !important;
}
</style>
