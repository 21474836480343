<template>
  <v-sheet color="white" flat class="mt-1">
    <v-row dense class="secondary">
      <v-col cols="12" class="white"><h3 class="mx-2">SAN</h3></v-col>
      <v-col cols="12" class="pt-0">
        <v-data-table
          :headers="headerTableSan"
          :items="recordsSan"
          :calculate-widths="false"
          class="main-table elevation-0 mt-0 rounded-0"
          :loading="loading"
          loading-text="Caricamento dati... Attendere, per favore."
          multi-sort
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense class="secondary pt-2">
      <v-col cols="12" class="white"><h3 class="mx-2">NAS</h3></v-col>
      <v-col cols="12" class="pt-0">
        <v-data-table
          :headers="headerTableNas"
          :items="recordsNas"
          :calculate-widths="false"
          class="main-table elevation-0 mt-0 rounded-0"
          :loading="loading"
          loading-text="Caricamento dati... Attendere, per favore."
          multi-sort
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
export default {
  mixins: [connectionMixin],
  data: () => ({
    loading: false,
    recordsSan: [],
    recordsNas: [],
  }),
  computed: {
    headerTableSan() {
      return [
        {
          text: "[Node] : \nDisplay Label",
          align: "center",
          value: "nodeDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text",
          width: 100,
        },
        {
          text: "[Node] : \nNode Is Virtual",
          align: "center",
          value: "nodeNodeIsVirtual",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "MASTER - Storage",
          align: "center",
          value: "masterStorage",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "MASTER - OS Name",
          align: "center",
          value: "masterOsName",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "MASTER - CalculatedLocation",
          align: "center",
          value: "masterCalculatedLocation",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "MASTER - LifecycleStatus",
          align: "center",
          value: "masterLifecycleStatus",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "SLAVE - Storage",
          align: "center",
          value: "slaveStorage",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "SLAVE - OS Name",
          align: "center",
          value: "slaveOsName",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "SLAVE - CalculatedLocation",
          align: "center",
          value: "slaveCalculatedLocation",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "SLAVE - LifecycleStatus",
          align: "center",
          value: "slaveLifecycleStatus",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
      ];
    },
    headerTableNas() {
      return [
        {
          text: "[Node] : \nDisplay Label",
          align: "center",
          value: "nodeDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text",
          width: 100,
        },
        {
          text: "[Filer Fisico] : \nDisplay Label",
          align: "center",
          value: "filerFisicoDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text",
          cellClass: "cellFont",
        },
        {
          text: "[Filer Fisico] : \nCalculatedLocation",
          align: "center",
          value: "filerFisicoCalculatedLocation",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "[FileSystemExport] : \nDisplay Label",
          align: "center",
          value: "fileSystemExportDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
        {
          text: "[FileSystemExport] : \n[FileSystem]: \nDisplay Label",
          align: "center",
          value: "fileSystemExportFileSystemDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text",
        },
      ];
    },
  },
  mounted() {
    this.getDataSan();
    this.getDataNas();
  },
  methods: {
    async getDataSan() {
      this.loading = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.$route.params.id +
            this.$env.value("API_DEPENDENCIES_STORAGE_SAN"),
          this.opts
        );
        this.recordsSan = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    async getDataNas() {
      this.loading = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.$route.params.id +
            this.$env.value("API_DEPENDENCIES_STORAGE_NAS"),
          this.opts
        );
        this.recordsNas = response.data;
        this.loading = false;
      } catch (error) {
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.headerFont {
  font-size: 20px;
}
.v-data-table >>> tbody td {
  font-size: 12px !important;
}
.main-table table {
  table-layout: fixed;
}
</style>
