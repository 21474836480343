<template>
  <v-sheet color="white" flat class="mt-1">
    <v-row dense>
      <v-col cols="2" class="text-center">
        <div class="legend-title">Legenda</div>
      </v-col>
      <v-col cols="4" class="text-center font-weight-bold">IN</v-col>
      <v-col cols="2" class="text-center"></v-col>
      <v-col cols="4" class="text-center font-weight-bold">OUT</v-col>
    </v-row>
    <v-row dense style="height: 100%">
      <!-- <v-col cols="1" class="text-center">
        <div id="legendnet"></div>
      </v-col> -->
      <v-col
        cols="2"
        style="max-height: 100%; overflow: scroll; padding-bottom: 50px"
      >
        <div class="legend-container">
          <!-- <div class="legend-title">Legenda</div> -->
          <div class="legend-item">
            <div class="legend-item-symbol-yellow-square"></div>
            <div class="legend-item-label">Scheda di dettaglio</div>
          </div>
          <div class="legend-item">
            <div class="legend-item-symbol-green-square"></div>
            <div class="legend-item-label">Infrastructure Service</div>
          </div>
          <div class="legend-item">
            <div class="legend-item-symbol-blue-square"></div>
            <div class="legend-item-label">Business Service</div>
          </div>
          <div class="legend-item">
            <div class="legend-item-symbol-gray-square"></div>
            <div class="legend-item-label">External Service</div>
          </div>
          <div class="legend-item">
            <div class="legend-item-symbol-weak"></div>
            <div class="legend-item-label">Legame Debole</div>
          </div>
          <div class="legend-item">
            <div class="legend-item-symbol-strong"></div>
            <div class="legend-item-label">Legame Forte</div>
          </div>
          <div class="legend-item">
            <div class="legend-item-symbol-stronger"></div>
            <div class="legend-item-label">Legame Forte di Consistenza</div>
          </div>
        </div>
      </v-col>
      <v-col cols="10">
        <div id="mynetwork"></div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { DataSet, Network } from "vis-network/standalone";
import connectionMixin from "@/mixins/connectionParams.js";
export default {
  name: "Dependencies",
  mixins: [connectionMixin],
  props: { card_came: Number, card_name: String },
  data() {
    return {
      dt: DataSet,
      visibilityTooltip: "d-none",
      nodes: [],
      edges: [],
      options: {
        nodes: {
          size: 540,
          shape: "box",
          font: { multi: "html", face: "roboto", align: "left", size: 32 },
          margin: 24,
          widthConstraint: 600,
          heightConstraint: { minimum: 100, valign: "middle" },
        },
        interaction: {
          hover: true,
        },
        manipulation: false,
        autoResize: true,
        clickToUse: false,
        height: "100%",
        width: "100%",
        layout: {
          randomSeed: undefined,
          improvedLayout: true,
          hierarchical: {
            enabled: true,
            levelSeparation: 700,
            nodeSpacing: 300,
            treeSpacing: 200,
            blockShifting: true,
            edgeMinimization: true,
            parentCentralization: true,
            direction: "LR", //UD, DU, LR, RL,
            sortMethod: "directed", //hubsize, directed
            shakeTowards: "leaves", //roots, leaves
          },
        },
        physics: {
          hierarchicalRepulsion: {
            nodeDistance: 400,
          },
        },
        groups: {
          nodeGroupBS: {
            size: 540,
            color: "#2196F3",
          },
          nodeGroupIS: {
            size: 540,
            color: "#007C80",
          },
          nodeGroupES: {
            size: 540,
            color: "#b5b5b5",
          },
          nodeGroupCenter: {
            size: 540,
            color: "#fdd300",
            font: { multi: "html", face: "roboto", align: "center", size: 32 },
          },
        },
      },
      container: null,
      network: null,
      legend: null,
      netFrom: [],
      netTo: [],
      dependences: {},
      legami: {
        LEGAME_DEBOLE: "LEGAME DEBOLE",
        LEGAME_FORTE: "LEGAME FORTE (RTO)",
        LEGAME_FORTE_CONSISTENZA: "LEGAME FORTE DI CONSISTENZA (RTO+RPO)",
      },
    };
  },
  computed: {
    graph_data() {
      return {
        nodes: this.nodes,
        edges: this.edges,
      };
    },
  },
  methods: {
    //Create tooltips
    htmlTitle(html) {
      const container = document.createElement("div");
      container.innerHTML = html;
      return container;
    },
    createCentralNode() {
      if (this.dependences.rto == null) {
        this.dependences.rto = "";
      }
      if (this.dependences.disponibilita == null) {
        this.dependences.disponibilita = "";
      }
      if (this.dependences.classeServizio == null) {
        this.dependences.classeServizio = "";
      }
      if (this.dependences.statoServizio == null) {
        this.dependences.statoServizio = "";
      }
      this.nodes.push({
        id: "central_node",
        label:
          "<b>" +
          this.setCardName(this.card_name) +
          "</b>" +
          "\n\n " +
          this.dependences.statoServizio +
          "\n\n " +
          "RTO " +
          this.dependences.rto +
          " | " +
          "Disp. " +
          this.dependences.disponibilita +
          " | " +
          this.dependences.classeServizio,

        group: "nodeGroupCenter",
        title: this.htmlTitle(
          this.setCardName(this.card_name) + "<br><i>" + this.card_came + "</i>"
        ),
      });
    },
    async getCardDependencies() {
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            "/" +
            this.$route.params.id +
            this.$env.value("API_DEPENDENCIES"),
          this.opts
        );
        this.dependences = response.data;
        this.netFrom = response.data.from;
        this.netTo = response.data.to;
        this.createCentralNode();
        this.createNetwork();
        // this.createLegend();
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    setCardName(name) {
      if (name == null) {
        return "SERVIZIO ESTERNO";
      } else {
        return name;
      }
    },
    createNetwork() {
      var forId = 0;
      this.netFrom.forEach((item) => {
        forId++;
        item.ucmdbId = item.ucmdbId + forId;
        this.addsNode(item, item.ucmdbId, "central_node");
      });
      this.netTo.forEach((item) => {
        forId++;
        item.ucmdbId = item.ucmdbId + forId;
        this.addsNode(item, "central_node", item.ucmdbId);
      });
      this.container = document.getElementById("mynetwork");
      this.network = new Network(this.container, this.graph_data, this.options);
      this.activeEvents();
    },
    addsNode(item, from, to) {
      let labelNode = this.setCardName(item.name) + "\n\n ";
      if (item.statoServizio) {
        labelNode = labelNode + item.statoServizio + "\n\n ";
      }
      if (item.rto) {
        labelNode = labelNode + "RTO ";
        labelNode = labelNode + item.rto + " | ";
      }

      if (item.disponibilita) {
        labelNode = labelNode + "Disp. ";
        labelNode = labelNode + item.disponibilita + " | ";
      }
      if (item.classeServizio) {
        labelNode = labelNode + item.classeServizio;
      }
      this.nodes.push({
        id: item.ucmdbId,
        title: item.name,
        label: labelNode,
        group: this.checkType(item.type),
      });
      this.addsEdge(from, to, item.def);
    },
    checkType(type) {
      let group;
      if (type == "business_service") {
        group = "nodeGroupBS";
      }
      if (type == "infrastructure_service") {
        group = "nodeGroupIS";
      }
      if (type == "external_services") {
        group = "nodeGroupES";
      }
      return group;
    },
    addsEdge(from, to, dependence) {
      this.edges.push({
        from: from,
        to: to,
        arrows: "to",
        dashes: dependence.relType === this.legami.LEGAME_DEBOLE ? true : false,
        title: this.edgeDependence(dependence),
        physics: true,
        width:
          dependence.relType === this.legami.LEGAME_FORTE_CONSISTENZA ? 8 : 2,
        smooth: {
          type: "cubicBezier",
          forceDirection: "",
        },
      });
    },
    edgeDependence(definition) {
      var list = document.createElement("div");
      var dnone = "d-show";
      if (definition.depType === "null") {
        dnone = "d-none";
      }
      const html =
        `<div data-v-1b4c564e="" class="mx-auto v-sheet theme--light elevation-0" color="#ffff" style="max-width: 400px; background-color: rgb(245, 244, 237); border-color: rgb(245, 244, 237);">

          <div data-v-1b4c564e="" tabindex="-1" class="` +
        dnone +
        ` v-list-item v-list-item--two-line theme--light">
            <div data-v-1b4c564e="" class="v-list-item__content">
              <div data-v-1b4c564e="" class="v-list-item__title">` +
        definition.depType +
        `</div>
              <div data-v-1b4c564e="" class="v-list-item__subtitle">tipo dipendenza</div>
            </div>
          </div>

          <div data-v-1b4c564e="" tabindex="-1" class="v-list-item v-list-item--two-line theme--light">
            <div data-v-1b4c564e="" class="v-list-item__content">
              <div data-v-1b4c564e="" class="v-list-item__title">` +
        definition.relType +
        `</div>
              <div data-v-1b4c564e="" class="v-list-item__subtitle">tipo legame</div>
            </div>
          </div>
        </div>`;

      list.innerHTML = html;
      return list;
    },
    createLegend() {
      var container = document.getElementById("legendnet");
      var nodeslegend = [];
      var edgeslegend = [];
      var data = {
        nodes: nodeslegend,
        edges: edgeslegend,
      };
      var options = {
        height: "100%",
        width: "100%",
        nodes: {
          scaling: {
            min: 8,
            max: 16,
          },
          font: "10px roboto",
          widthConstraint: {
            minimum: 80,
            maximum: 100,
          },
          heightConstraint: {
            minimum: 20,
            valign: "middle",
          },
        },
        edges: {
          smooth: false,
        },
        autoResize: true,
        interaction: {
          zoomView: false,
          dragView: false,
        },
      };
      var x = -container.clientWidth / 2 + 50;
      var y = -container.clientHeight / 2;
      var step = 70;
      nodeslegend.push({
        id: 1010,
        x: x,
        y: y + 30,
        font: { multi: true, size: 14, face: "roboto" },
        label: "<b>Legenda</b>",
        color: "#000000",
        value: 1,
        shape: "text",
        fixed: true,
        physics: false,
      });
      nodeslegend.push({
        id: 1000,
        x: x,
        y: y + step,
        label: "Scheda di dettaglio",
        widthConstraint: {
          maximum: 75,
        },
        color: "#fdd300",
        value: 1,
        shape: "square",
        fixed: true,
        physics: false,
      });
      nodeslegend.push({
        id: 1001,
        x: x,
        y: y + 2 * step,
        label: "Infrastructure Service",
        color: "#007C80",
        shape: "square",
        value: 1,
        fixed: true,
        physics: false,
      });
      nodeslegend.push({
        id: 1002,
        x: x,
        y: y + 3 * step,
        label: "Business Service",
        widthConstraint: {
          maximum: 80,
        },
        color: "#2196F3",
        shape: "square",
        value: 1,
        fixed: true,
        physics: false,
      });
      nodeslegend.push({
        id: 1003,
        x: x,
        y: y + 4 * step,
        label: "External Service",
        widthConstraint: {
          maximum: 75,
        },
        color: "#b5b5b5",
        shape: "square",
        value: 1,
        fixed: true,
        physics: false,
      });
      this.legend = new Network(container, data, options);
    },
    activeEvents() {
      var canvas = document
        .getElementById("mynetwork")
        .getElementsByTagName("canvas")[0];
      this.network.on("hoverNode", function () {
        canvas.style.cursor = "pointer";
      });
      this.network.on("hoverEdge", function () {
        canvas.style.cursor = "pointer";
      });
      this.network.on("blurNode", function () {
        canvas.style.cursor = "default";
      });
      this.network.on("blurEdge", function () {
        canvas.style.cursor = "default";
      });
    },
  },
  mounted() {
    this.getCardDependencies();
  },
};
</script>

<style scoped>
#mynetwork {
  width: 100%;
  height: calc(100vh - 320px);
}
#legendnet {
  max-width: 80px;
  height: calc(100vh - 350px);
}
.bkgrdcolor {
  background-color: "#f5f4ed";
}
.mt-1.v-sheet.theme--light.white {
  height: calc(100vh - 360px);
}
.legend-container {
  width: 90%;
  margin-inline: 5%;
  zoom: 0.7;
}
@media (min-height: 820px) {
  .legend-container {
    zoom: 0.8;
  }
}
@media (min-height: 900px) {
  .legend-container {
    zoom: 0.95;
  }
}
.legend-title {
  font-weight: bold;
  font-size: 1.1em;
}
.legend-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.legend-item-label {
  text-align: center;
  line-height: 1em;
  font-size: 0.8em;
  margin-top: 8px;
}
.legend-item-symbol-yellow-square {
  height: 30px;
  aspect-ratio: 1/1;
  background-color: #fdd300;
  border: 1px solid gray;
}
.legend-item-symbol-green-square {
  height: 30px;
  aspect-ratio: 1/1;
  background-color: #007c80;
  border: 1px solid gray;
}
.legend-item-symbol-blue-square {
  height: 30px;
  aspect-ratio: 1/1;
  background-color: #2196f3;
  border: 1px solid gray;
}
.legend-item-symbol-gray-square {
  height: 30px;
  aspect-ratio: 1/1;
  background-color: #b5b5b5;
  border: 1px solid gray;
}
.legend-item-symbol-weak {
  border-bottom: 2px dashed #1e1e1e;
  width: 70%;
}
.legend-item-symbol-strong {
  border-bottom: 2px solid #1e1e1e;
  width: 70%;
}
.legend-item-symbol-stronger {
  border-bottom: 4px solid #1e1e1e;
  width: 70%;
}
</style>
