<template>
  <v-dialog
    v-model="dialogAlert"
    scrollable
    width="500"
    @click:outside="closedialogAlert()"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list two-line>
          <template>
            <p v-for="(item, i) in alert" :key="i" v-html="item"></p>
          </template>
        </v-list>
        <v-spacer></v-spacer>
        <p>
          NOTA BENE: avvisare i colleghi che lavorano sul piano, di riaggiornare
          eventuali pagine / schede aperte sul piano.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closedialogAlert"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogAlert: {
      default: false,
      type: Boolean,
    },
    alert: {
      type: Array,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closedialogAlert() {
      this.$emit("open-dialog-alert", false);
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
</style>
