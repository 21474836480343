<template>
  <v-card>
    <v-card-title class="search-row-dttopologiars">
      <v-text-field
        class="searchStyle"
        v-model="search"
        label="Cerca"
        single-line
        hide-details
      ></v-text-field>
      <v-btn rounded color="primary" @click="getDataRs()">
        <v-icon small class="mr-2"> mdi-magnify </v-icon>
      </v-btn>

      <v-checkbox
        class="small-label"
        v-model="infoAT"
        label="Mostra solo anomalie e punti di attenzione"
        hide-details
        @change="getDataRs()"
      ></v-checkbox>

      <v-checkbox
        class="small-label"
        v-model="infoGDC"
        label="Mostra solo RS al GDC"
        hide-details
        @change="getDataRs()"
      ></v-checkbox>

      <v-checkbox
        class="small-label"
        v-model="infoBA"
        label="Mostra info BA"
        hide-details
        @change="getDataRs()"
      ></v-checkbox>

      <v-checkbox
        class="small-label"
        v-model="infoLO"
        label="Mostra info LO"
        hide-details
        @change="getDataRs()"
      ></v-checkbox>

      <v-checkbox
        class="small-label"
        v-model="infoNode"
        label="Mostra info Node"
        hide-details
        @change="getDataRs()"
      ></v-checkbox>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      v-if="reload"
      dense
      :headers="headerTableRs"
      :items="recordsRs"
      :calculate-widths="false"
      class="main-table elevation-1 mt-0"
      :loading="loading"
      loading-text="Caricamento dati... Attendere, per favore."
      multi-sort
      fixed-header
      :items-per-page="-1"
      height="calc(100vh - 450px)"
      :item-class="itemRowBackground"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.rsDisplayLabel"
                        label="[Running Software] : Display Label"
                        :disabled="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.s0DisplayLabel"
                        label="[SolutionL0] : Display Label"
                        :disabled="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.rsDiscoveredProductName"
                        label="Discovered Product Name"
                        :disabled="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.sequenceStart"
                        label="ENI Start Sequence"
                        type="number"
                        v-on:change="checkItem"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        :items="resilienzaOpt"
                        label="ENI Resilienza RS"
                        v-model="editedItem.resilienceApplication"
                        v-on:change="checkItem"
                      ></v-select>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.issue"
                        label="ENI Issue"
                        v-on:change="checkItem"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.resilienceApplicationCI"
                        label="ENI Resilienza CI RS"
                        rows="1"
                        :counter="4000"
                        :rules="[checktextArea]"
                        v-on:change="checkItem"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.noteResiliency"
                        label="ENI Note"
                        rows="1"
                        :counter="4000"
                        :rules="[checktextAreaTwo]"
                        v-on:change="checkItem"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions v-if="edit">
                <v-spacer></v-spacer>
                <v-btn color="black darken-1" text @click="close">
                  Annulla
                </v-btn>
                <v-btn
                  color="black darken-1"
                  text
                  @click="save"
                  :disabled="!texAreaError || !texAreaErrorTwo || itemChanged"
                >
                  Conferma
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`header.complianceStatus`]>
        <div class="text-center">
          <v-icon style="color: white">mdi-list-status</v-icon>
        </div>
      </template>
      <template v-slot:[`header.sequenceStatus`]>
        <div class="text-center">
          <v-icon style="color: white">mdi-counter</v-icon>
        </div>
      </template>
      <template v-slot:[`body.prepend`]>
        <tr class="primary lighten-4 dt-topologia-search-column">
          <td v-if="edit"></td>
          <td>
            <div class="text-center">
              <v-select
                v-model="selectedComplianceStatus"
                :items="complianceStatuses"
                item-text="label"
                return-object
                single-line
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-icon v-if="index < 1" :color="item.color">
                    mdi-circle
                  </v-icon>
                  <span
                    v-if="index === 1"
                    class="text-grey text-caption align-self-center"
                  >
                    +{{ selectedComplianceStatus.length - 1 }}
                  </span>
                </template>
              </v-select>
            </div>
          </td>
          <td>
            <div class="text-center">
              <v-select
                v-model="selectedSequenceStatus"
                :items="sequenceStatuses"
                item-text="label"
                return-object
                single-line
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-icon v-if="index < 1" :color="item.color">
                    mdi-circle
                  </v-icon>
                  <span
                    v-if="index === 1"
                    class="text-grey text-caption align-self-center"
                  >
                    +{{ selectedSequenceStatus.length - 1 }}
                  </span>
                </template>
              </v-select>
            </div>
          </td>
          <td v-if="infoBA">
            <v-text-field
              v-model="businessApplication"
              type="text"
              label="BusinessApplication"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td v-if="infoLO">
            <v-text-field
              v-model="solutionL0"
              type="text"
              label="SolutionL0"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="nodeDL"
              type="text"
              label="[Node]:DL"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td v-if="infoNode">
            <v-text-field
              v-model="lifecycleStatus"
              type="text"
              label="LifecycleStatus"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td v-if="infoNode">
            <v-text-field
              v-model="calculatedLocation"
              type="text"
              label="CalculatedLocation"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="runningSoftware"
              type="text"
              label="Running Software"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="discoveredProductName"
              type="text"
              label="Discovered Product Name"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="rsLifecycleStatus"
              type="text"
              label="RS LifecycleStatus"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="startSequence"
              type="text"
              label="Start Sequence"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="resilienzaRS"
              type="text"
              label="Resilienza RS"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="resilienzaCI"
              type="text"
              label="Resilienza CI RS"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="rsOwnerDL"
              type="text"
              label="RS Owner Display Label"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="clusterType"
              type="text"
              label="Cluster Type"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="eniIssue"
              type="text"
              label="Issue"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="eniNote"
              type="text"
              label="Note"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="checkCompliance"
              type="text"
              label="Conformità Classe di Servizio"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.actions`]="{ item }" v-if="edit">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-if="item.rsDisplayLabel !== ''"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:[`item.complianceStatus`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              :style="{ color: getComplianceStatusColor(item) }"
            >
              mdi-circle
            </v-icon>
          </template>
          <div>
            <span>
              <span style="font-weight: bold; color: #fdd300">
                {{ item.complianceStatus }}
              </span>
              <span
                v-if="
                  (item.complianceStatus === 'CONFORME CON NOTE' ||
                    item.complianceStatus === 'NON CONFORME') &&
                  item.checkCompliance
                "
              >
                <span style="font-weight: bold"> - </span>
                <span>
                  {{ item.checkCompliance.split(" - ").slice(-1).pop() }}
                </span>
              </span>
            </span>
          </div>
          <div
            v-if="
              item.complianceStatus.includes('CONFORME CON RTO SELETTIVO') &&
              item.resilienceApplication === 'NA_BIZ'
            "
            style="font-weight: bold; color: #ff5252; max-width: 400px"
          >
            N.B. per questo Running Software è stato accettato dal Business un
            RTO “selettivo” maggiore di quanto indicato nella VDR - vedi il
            campo “[Running Software]: Note”
          </div>
        </v-tooltip>
      </template>
      <template v-slot:[`item.sequenceStatus`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              :style="{ color: getSequenceStatusColor(item) }"
            >
              mdi-circle
            </v-icon>
          </template>
          <div>
            <span style="font-weight: bold; color: #fdd300">
              {{ item.sequenceStatus }}
            </span>
          </div>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import connectionMixin from "@/mixins/connectionParams.js";
export default {
  name: "DataTableTopologiaRs",
  mixins: [connectionMixin],
  props: {
    edit: Boolean,
  },
  data: () => ({
    loading: false,
    recordsRs: [],
    search: "",
    infoAT: false,
    infoGDC: false,
    infoBA: false,
    infoLO: false,
    infoNode: false,
    header: [],
    businessApplication: "",
    solutionL0: "",
    nodeDL: "",
    lifecycleStatus: "",
    calculatedLocation: "",
    runningSoftware: "",
    discoveredProductName: "",
    rsLifecycleStatus: "",
    startSequence: "",
    resilienzaRS: "",
    resilienzaCI: "",
    rsOwnerDL: "",
    clusterType: "",
    eniIssue: "",
    eniNote: "",
    checkCompliance: "",
    dialog: false,
    reload: true,
    editedIndex: -1,
    editedItem: {
      text: "",
      align: "",
      value: "",
      sortable: true,
      class: "",
    },

    defaultItem: {
      text: "",
      align: "",
      value: "",
      sortable: true,
      class: "",
    },
    resilienzaOpt: ["TBD", "SI", "NO", "NA_TECH", "NA_BIZ"],
    texAreaError: false,
    texAreaErrorTwo: false,
    itemChanged: true,
    complianceStatuses: [
      { label: "", color: "#444", index: -1 },
      { label: "N/A - NOPROD", color: "#444", index: 0 },
      { label: "FUORI PERIMETRO", color: "#444", index: 1 },
      { label: "CONFORME", color: "#4CAF50", index: 2 },
      {
        label: "CONFORME CON RTO SELETTIVO (NA_BIZ)",
        color: "#2196F3",
        index: 3,
      },
      { label: "CONFORME CON NOTE", color: "#fdd300", index: 4 },
      { label: "NON CONFORME", color: "#FF5252", index: 5 },
      { label: "CLASSE SERVIZIO NON RICONOSCIUTA", color: "#FF5252", index: 6 },
      { label: "CLASSE SERVIZIO NON PRESENTE", color: "#FF5252", index: 7 },
    ],
    selectedComplianceStatus: [],
    sequenceStatuses: [
      { label: "", color: "#444", index: -1 },
      { label: "N/A - NOPROD", color: "#444", index: 0 },
      { label: "SEQUENZA ASSENTE", color: "#FF5252", index: 1 },
      { label: "SEQUENZA PRESENTE", color: "#4CAF50", index: 2 },
    ],
    selectedSequenceStatus: [],
  }),
  computed: {
    headerTableRs() {
      this.setHeader();
      return this.header;
    },
  },
  mounted() {
    this.getDataRs();
  },
  methods: {
    getComplianceStatusColor(rs) {
      const cs = this.complianceStatuses.find(
        (el) => el.label === rs.complianceStatus
      );

      return cs.color;
    },
    getSequenceStatusColor(rs) {
      const cs = this.sequenceStatuses.find(
        (el) => el.label === rs.sequenceStatus
      );

      return cs.color;
    },
    setHeader() {
      let that = this;
      this.header = [
        {
          text: "Compliance Status",
          align: "center",
          value: "complianceStatus",
          sortable: true,
          sort: (a, b) => {
            const aind = that.complianceStatuses.find(
              (el) => el.label === a
            ).index;
            const bind = that.complianceStatuses.find(
              (el) => el.label === b
            ).index;
            return aind - bind;
          },
          class: "primary lighten-4 white--text text-top white-bg",
          width: 100,
          filter: (value) => {
            return this.selectedComplianceStatus.length > 0
              ? this.selectedComplianceStatus.find((el) => el.label === value)
              : true;
          },
        },
        {
          text: "Sequence Status",
          align: "center",
          value: "sequenceStatus",
          sortable: true,
          sort: (a, b) => {
            const aind = that.sequenceStatuses.find(
              (el) => el.label === a
            ).index;
            const bind = that.sequenceStatuses.find(
              (el) => el.label === b
            ).index;
            return aind - bind;
          },
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (value) => {
            return this.selectedSequenceStatus.length > 0
              ? this.selectedSequenceStatus.find((el) => el.label === value)
              : true;
          },
        },
        {
          text: "[Node] : Display Label",
          align: "center",
          value: "nodeDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 110,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["nodeDL"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nDisplay Label",
          align: "center",
          value: "rsDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["runningSoftware"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nDiscovered Product Name",
          align: "center",
          value: "rsDiscoveredProductName",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          cellClass: "cellFont",
          width: 190,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["discoveredProductName"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nLifecycleStatus",
          align: "center",
          value: "rsLifecycleStatus",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["rsLifecycleStatus"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nENI Start Sequence",
          align: "center",
          value: "sequenceStart",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["startSequence"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nENI Resilienza RS",
          align: "center",
          value: "resilienceApplication",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["resilienzaRS"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nENI Resilienza CI RS",
          align: "center",
          value: "resilienceApplicationCI",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["resilienzaCI"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \n[Owner] - Display Label",
          align: "center",
          value: "rsOwnerDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["rsOwnerDL"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nRS Cluster type",
          align: "center",
          value: "rsClusterType",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["clusterType"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nENI Issue",
          align: "center",
          value: "issue",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["eniIssue"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nENI Note",
          align: "center",
          value: "noteResiliency",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["eniNote"].toLowerCase());
          },
        },
        {
          text: "[Running Software] : \nENI Conformità Classe di Servizio",
          align: "center",
          value: "checkCompliance",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 160,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["checkCompliance"].toLowerCase());
          },
        },
      ];

      // smarter way to do it
      // splice(i, 0, item) items in reverse order
      // [splice to pos i] [ ... array ]
      //                   \
      // [unshift to pos i] [becomes pos i+1] [ ... array ]
      //                   \               \
      // [unshift to pos i] [becomes pos i+1] [becomes pos i+2] [ ... array ]

      // todo : check order after unshift
      if (this.infoNode) {
        this.header.splice(2, 0, {
          text: "[Node] : CalculatedLocation",
          align: "center",
          value: "nodeCalculatedLocation",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["calculatedLocation"].toLowerCase());
          },
        });
        this.header.splice(2, 0, {
          text: "[Node] : LifecycleStatus",
          align: "center",
          value: "nodeLifecycleStatus",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 150,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["lifecycleStatus"].toLowerCase());
          },
        });
      }
      if (this.infoLO) {
        this.header.splice(2, 0, {
          text: "[SolutionL0] : Display Label",
          align: "center",
          value: "s0DisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          cellClass: "cellFont",
          width: 110,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["solutionL0"].toLowerCase());
          },
        });
      }
      if (this.infoBA) {
        this.header.splice(2, 0, {
          text: "[BusinessApplication] : \nDisplay Label",
          align: "center",
          value: "baDisplayLabel",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 160,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["businessApplication"].toLowerCase());
          },
        });
      }
      if (this.edit) {
        this.header.splice(0, 0, {
          text: "Action",
          align: "center",
          value: "actions",
          sortable: false,
          class: "primary lighten-4 white--text text-top",
        });
      }
    },
    editItem(item) {
      this.itemChanged = true;
      this.editedIndex = this.recordsRs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    itemRowBackground: function (item) {
      let backGround = "";
      if (item.rsDisplayLabel == "") {
        backGround = "backgroundColorRed";
      }
      var index = this.recordsRs.indexOf(item);
      if (this.recordsRs[index].edit == true) {
        backGround = "backgroundColor";
      }
      console.log(backGround);

      backGround += this.edit ? " rowtwothreewhite" : " rowonetwowhite";
      return backGround;
    },
    checkItem() {
      if (
        this.recordsRs[this.editedIndex].noteResiliency ==
          this.editedItem.noteResiliency &&
        this.recordsRs[this.editedIndex].resilienceApplicationCI ==
          this.editedItem.resilienceApplicationCI &&
        this.recordsRs[this.editedIndex].resilienceApplication ==
          this.editedItem.resilienceApplication &&
        this.recordsRs[this.editedIndex].sequenceStart ==
          this.editedItem.sequenceStart &&
        this.recordsRs[this.editedIndex].issue == this.editedItem.issue
      ) {
        this.itemChanged = true;
      } else {
        this.itemChanged = false;
      }
    },
    save() {
      if (this.editedIndex > -1) {
        this.recordsRs.forEach((el, idx) => {
          if (
            el.keys.schedaUcmdbId ==
              this.recordsRs[this.editedIndex].keys.schedaUcmdbId &&
            idx != this.editedIndex
          ) {
            if (
              this.recordsRs[this.editedIndex].noteResiliency !==
              this.editedItem.noteResiliency
            ) {
              el.noteResiliency = this.editedItem.noteResiliency;
              el.edit = true;
            }
            if (
              this.recordsRs[this.editedIndex].resilienceApplicationCI !==
              this.editedItem.resilienceApplicationCI
            ) {
              el.resilienceApplicationCI =
                this.editedItem.resilienceApplicationCI;
              el.edit = true;
            }
            if (
              this.recordsRs[this.editedIndex].resilienceApplication !==
              this.editedItem.resilienceApplication
            ) {
              el.resilienceApplication = this.editedItem.resilienceApplication;
              el.edit = true;
            }
            if (
              this.recordsRs[this.editedIndex].sequenceStart !==
              this.editedItem.sequenceStart
            ) {
              el.sequenceStart = this.editedItem.sequenceStart;
              el.edit = true;
            }
            if (
              this.recordsRs[this.editedIndex].issue !== this.editedItem.issue
            ) {
              el.issue = this.editedItem.issue;
              el.edit = true;
            }
          }
        });
        Object.assign(this.recordsRs[this.editedIndex], this.editedItem);
        this.recordsRs[this.editedIndex].edit = true;
        this.$emit("modifiedRs", this.recordsRs);
        this.close();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async getDataRs() {
      var bodyData = {
        onlyAnomalies: this.infoAT,
        onlyGDC: this.infoGDC,
        infoBA: this.infoBA,
        infoL0: this.infoLO,
        infoNode: this.infoNode,
      };
      this.loading = true;
      try {
        let url =
          this.$env.value("API_HOST") +
          this.$env.value("API_CARDS") +
          "/" +
          this.$route.params.id +
          this.$env.value("API_RUN_SOFTWARE");
        if (this.search !== "") url += "/" + this.search;
        const response = await this.$http.post(url, bodyData, this.opts);
        this.recordsRs = response.data;
        this.loading = false;
      } catch (error) {
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    checktextArea(input) {
      this.texAreaError = true;
      if (input && input.length > 4000) {
        this.texAreaError = false;
      } else {
        this.texAreaError = true;
      }
      return this.texAreaError;
    },
    checktextAreaTwo(input) {
      this.texAreaErrorTwo = false;
      if (input && input.length > 4000) {
        this.texAreaErrorTwo = false;
      } else {
        this.texAreaErrorTwo = true;
      }
      return this.texAreaErrorTwo;
    },
    clearTable() {
      this.reload = false;
      this.recordsRs.forEach((el) => {
        if (el.edit) {
          el.edit = false;
        }
        setTimeout(() => {
          this.reload = true;
        }, 200);
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    //  this.initialize();
  },
};
</script>

<style scoped>
::v-deep .small-label .v-label {
  font-size: 0.8em;
}

::v-deep .rowonetwowhite td:nth-of-type(1),
::v-deep .rowonetwowhite td:nth-of-type(2),
::v-deep .rowtwothreewhite td:nth-of-type(2),
::v-deep .rowtwothreewhite td:nth-of-type(3) {
  background: white !important;
}

.headerFont {
  font-size: 20px;
}
.text-center {
  text-align: center;
}
.v-data-table >>> tbody td {
  font-size: 12px !important;
}
/* .v-data-table >>> tbody tr:not(:first-of-type) td:nth-of-type(2) {
  background: white;
}
.v-data-table >>> tbody tr:not(:first-of-type) td:nth-of-type(3) {
  background: white;
} */
.v-data-table >>> tbody td {
  font-size: 12px !important;
}
.main-table table {
  table-layout: fixed;
}
.v-input.searchStyle.v-input--hide-details.theme--light.v-text-field.v-text-field--single-line.v-text-field--is-booted {
  max-width: 20%;
}
.v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.primary--text {
  padding: 0px 15px;
}
.search-row-dttopologiars {
  margin-top: -24px;
}

.search-row-dttopologiars .v-btn {
  height: 26px;
  min-width: 36px;
  padding: 0 0px;
  margin-top: 15px;
}

.search-row-dttopologiars .v-btn .v-icon {
  margin-right: 0px !important;
}
.v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
  padding-left: 10px;
}
</style>
